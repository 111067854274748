<template>
  <v-data-table
    :headers="headers"
    :items="domaineInterventions"
    :items-per-page="5"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>
          Domaine d'intervention
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{item}">
      <v-icon
        small
        class="mr-2"
        @click="viewInfoCollect(item)"
      >
        mdi-eye
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import domaineIntervention from '@/services/domaine-intervention.js'
  export default {
    data () {
      return {
        dialog: false,
        dialogDelete: false,
        headers: [
          { text: 'Actions', value: 'actions', sortable: false },
          {
            text: 'Libellé du Projet',
            value: 'projet.libelle_projet',
          },
          {
            text: 'Date de collecte',
            value: 'date_collecte',
          },
          {
            text: 'Organisation',
            value: 'projet.organisation.libelle_organisation',
          },
          {
            text: 'Animateur',
            value: 'created_by.fullName',
          },
        ],
        domaineInterventions: [],
      }
    },
    mounted () {
      console.log(this.editedIndex)
      domaineIntervention.getDomaineInterventions().then(response => {
        this.domaineInterventions = response.data
        console.log(this.domaineIntervention)
      }).catch(err => {
        console.log(err.response.data)
      })
    },
    computed: {
      ...mapGetters(['getThemeMode']),
      formTitle () {
        return this.editedIndex === -1 ? 'Ajouter un profil' : 'Modifier un profil'
      },
    },
    methods: {
      ...mapActions(['changeThemeLayout']),
      viewInfoCollect (item) {
        this.$router.push({
          name: 'domaine-intervention.view',
          params: { collect: item.id },
        })
      },
    },
  }
</script>

<style scoped>

</style>
